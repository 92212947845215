
import { Component, Watch } from "vue-property-decorator";
import { Mixins } from "vue-mixin-decorator";
import GeneralMixin from "@/mixin";
import { Action, Getter, Mutation } from "vuex-class";
import {
  IStockListRequest,
  IStock,
  ITransportUnitPriceUpdateRequest
} from "@/types/stock";
import { IPagination, ISelectItem } from "@/types";
import { IWarehouseListRequest } from "@/types/warehouse";
import {
  TransportUnitPrice,
  TransportUnitPriceUpdateRequest
} from "@/models/stock";

@Component({})
export default class extends Mixins<GeneralMixin>(GeneralMixin) {
  // stock
  @Action("stock/adminGetList")
  public getStockList!: (request: IStockListRequest) => boolean;

  @Action("stock/adminUpdateTransportUnitPrice")
  public transportUnitPriceRegistration!: (
    params: ITransportUnitPriceUpdateRequest
  ) => Promise<boolean>;

  @Action("stock/adminMemoRegistration")
  public memoRegistration!: (data: {
    stock_id: number;
    requestMemo: { memo: string };
  }) => boolean;

  @Getter("stock/list")
  public stockList!: IStock[];

  @Getter("stock/pagination")
  public pagination!: IPagination<IStock[]>;

  @Mutation("stock/clear")
  public clear!: () => void;

  // warehouse
  @Action("warehouse/adminGetList")
  public getWarehouseList!: (request: IWarehouseListRequest) => boolean;

  @Getter("warehouse/selectItem")
  public warehouseList!: ISelectItem[];

  @Mutation("warehouse/clear")
  public warehouseClear!: () => void;

  // watch
  @Watch("$route.query")
  public async changeQuery() {
    this.queryToParams();
    await this.getStockList(this.requestParams);
  }

  // パラメータ定義
  public requestMemo = { memo: "" };
  public requestTransportUnitPrice: ITransportUnitPriceUpdateRequest =
    new TransportUnitPriceUpdateRequest({ stocks: [new TransportUnitPrice()] });
  public requestParams: IStockListRequest = {
    page: 1,
    warehouse_id: 0,
    in_stock: true,
    sort_date_column: "arrival_date",
    sort_direction: "",
    is_memo: false
  };

  //
  // variables
  //
  public memo_dialog = false;
  public transport_unit_price_dialog = false;
  public stock_id = 0;
  public sort_items = [
    { value: "", text: "選択なし" },
    { value: "asc", text: "昇順" },
    { value: "desc", text: "降順" }
  ];

  public headers = [
    { text: "入荷日", value: "arrival_date", sortable: false },
    { text: "倉庫", value: "warehouse_name", sortable: false },
    { text: "ロットNo", value: "lot_no", sortable: false },
    {
      text: "入荷数量",
      value: "storing_quantity",
      sortable: false,
      align: "end"
    },
    { text: "在庫数量", value: "quantity", sortable: false, align: "end" },
    {
      text: "仕入単価",
      value: "buying_unit_price",
      sortable: false,
      align: "end"
    },
    {
      text: "輸送費単価",
      value: "transport_unit_price",
      sortable: false,
      align: "end"
    },
    { text: "在庫メモ", value: "memo", sortable: false },
    {
      text: "倉庫移動処理",
      value: "movement",
      sortable: false
    }
  ];

  //ルール設定
  public rules = {
    transport_unit_price: [
      (v: number) => !v || v >= 0 || "0未満は入力できません",
      (v: string) =>
        !v ||
        /^([1-9]\d*|0)(\.\d{1,2})?$/.test(v) ||
        "小数点以下2桁までで入力してください"
    ]
  };

  //
  // methods
  //
  private async created() {
    this.clear();
    this.warehouseClear();
    this.queryToParams();

    await Promise.all([
      this.getStockList(this.requestParams),
      this.getWarehouseList({ per_page: 0 })
    ]);
  }

  // urlからパラメータをセットする
  private queryToParams() {
    const routeQuery = this.$route.query;
    const routeParams = this.$route.params;

    this.requestParams = {
      page: routeQuery.page ? Number(routeQuery.page) : 1,
      product_id: routeParams.product_id ? Number(routeParams.product_id) : 0,
      warehouse_id: routeQuery.warehouse_id
        ? Number(routeQuery.warehouse_id)
        : 0,
      in_stock:
        typeof routeQuery.in_stock !== "undefined"
          ? Boolean(routeQuery.in_stock)
          : true,
      sort_date_column: routeQuery.sort_date_column
        ? decodeURI(routeQuery.sort_date_column as string)
        : "arrival_date",
      sort_direction: routeQuery.sort_direction
        ? decodeURI(routeQuery.sort_direction as string)
        : "",
      is_memo: routeQuery.is_memo ? Boolean(routeQuery.is_memo) : false
    };
  }

  // 1ページ目から検索
  // 主に検索条件が変更されたときに利用
  public async searchFirst() {
    this.requestParams.page = 1;
    await this.paramsToQuery();
  }

  // URLのパラメータをセットする
  private paramsToQuery() {
    this.$router
      .push({
        path: this.$router.currentRoute.path,
        query: {
          page: this.requestParams.page ? String(this.requestParams.page) : "1",
          warehouse_id: this.requestParams.warehouse_id
            ? String(this.requestParams.warehouse_id)
            : "0",
          in_stock: this.requestParams.in_stock
            ? String(this.requestParams.in_stock)
            : "",
          sort_date_column: this.requestParams.sort_date_column,
          sort_direction: this.requestParams.sort_direction,
          is_memo: this.requestParams.is_memo
            ? String(this.requestParams.is_memo)
            : ""
        }
      })
      .catch(() => {});
  }

  //倉庫移動処理
  public warehouse_movement(item: IStock) {
    this.$router
      .push(`/admin/stock/${item.id}/movement/create`)
      .catch(() => {});
  }

  // 輸送費単価登録編集画面
  public clickTransportUnitPrice(item: IStock) {
    this.transport_unit_price_dialog = true;
    this.requestTransportUnitPrice.stocks[0].transport_unit_price =
      Number(item.transport_unit_price.toFixed(2)) || null;
    this.requestTransportUnitPrice.stocks[0].stock_id = item.id;
  }

  //在庫メモ登録編集画面
  public clickMemo(item: IStock) {
    this.memo_dialog = true;
    this.requestMemo.memo = item.memo || "";
    this.stock_id = item.id;
  }

  // 輸送費単価確定ボタンが押された際の挙動
  public async submitTransportUnitPrice() {
    this.transport_unit_price_dialog = false;
    if (this.requestTransportUnitPrice.stocks[0].transport_unit_price == null) {
      this.requestTransportUnitPrice.stocks[0].transport_unit_price = 0;
    }
    await this.transportUnitPriceRegistration(this.requestTransportUnitPrice);
    this.$router.go(0);
  }

  // 在庫メモ確定ボタンが押された際の挙動
  public async submitMemo() {
    this.memo_dialog = false;
    await this.memoRegistration({
      stock_id: this.stock_id,
      requestMemo: this.requestMemo
    });
    this.$router.go(0);
  }
}
